<template>
  <v-card elevation="6" v-if="!isLoggedIn">
    <v-card-text class="px-4">
      <v-row>
        <v-col cols="12" class="d-flex justify-end mb-0 pb-0">
          <v-btn icon @click="$store.commit('account/toggleDarkTheme')">
            <v-icon>mdi-theme-light-dark</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center pb-6">
          <v-avatar
            size="96"
            :class="darkTheme ? 'custom-avatar-dark' : 'custom-avatar'"
          >
            <img src="@/assets/images/inndot.jpg" alt="Inndot" />
          </v-avatar>
        </v-col>
        <!-- <v-col cols="12" class="d-flex justify-center mt-0 pt-0">
          <span>Gestor</span>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" class="my-0 py-0">
          <v-text-field
            prepend-icon="mdi-account"
            label="Usuario"
            type="text"
            ref="user"
            v-model="user"
            :rules="[() => !!user || 'Este campo es requerido']"
            :disabled="isLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-text-field
            prepend-icon="mdi-lock"
            label="Contraseña"
            :append-icon="passwordIcon"
            @click:append="switchPasswordField"
            :type="passwordType"
            ref="password"
            v-model="password"
            :rules="[() => !!password || 'Este campo es requerido']"
            :disabled="isLoading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-0 pt-0">
          <v-btn
            class="ma-2"
            :loading="isLoading"
            :disabled="isLoading"
            color="primary"
            block
            @click="logIn()"
          >
            Iniciar sesion
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- <v-card-actions class="px-4 pb-4">
      <v-spacer></v-spacer>
      <v-btn
        class="ma-2"
        :loading="isLoading"
        :disabled="isLoading"
        color="primary"
        @click="logIn()"
      >
        Iniciar sesion
      </v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      password: "",
      formHasErrors: false,
      passwordType: "password",
      passwordIcon: "mdi-eye",
      loader: null,
      buttonLoading: false,
    };
  },
  methods: {
    switchPasswordField() {
      if (this.passwordType === "text") {
        this.passwordType = "password";
        this.passwordIcon = "mdi-eye";
      } else {
        this.passwordType = "text";
        this.passwordIcon = "mdi-eye-off";
      }
    },
    resetForm() {
      this.errorMessages = [];
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    logIn() {
      let petition = true;
      this.buttonLoading = true;

      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors && petition) {
        let data = new FormData();

        data.append("user", this.user);
        data.append("pass", this.password);

        axios
          .post("public/Auth/login", data)
          .then((response) => {
            this.$store.commit("account/logIn", response.data.token);
            if (this.isLoggedIn && this.userToken != null) {
              this.$router.push({ name: "main" });
            }
          })
          .catch((error) => {
            console.log('Error: ', error);
          })
          .then(() => {
            this.buttonLoading = false;
          });
      }
    },
  },
  computed: {
    ...mapState("account", ["darkTheme", "isLoggedIn", "userToken"]),
    form() {
      return {
        user: this.user,
        password: this.password,
      };
    },
    isLoading() {
      return this.buttonLoading;
    },
  },
  beforeCreate() {
    if (this.$store.state.account.isLoggedIn) {
      this.$router.push({ name: "main" });
    }
  },
};
</script>
